import { Box, Grid, styled, Typography, useMediaQuery } from '@mui/material'
import {
  CartesianGrid,
  XAxis,
  YAxis,
} from '@flock/shared-ui/src/components/AccountProjectionChart/recharts'
import { Props } from 'recharts/types/component/DefaultLegendContent'

import React from 'react'
import { flockColors } from '@flock/flock-component-library'
import { Bar, BarChart, Legend, ResponsiveContainer } from 'recharts'
import {
  AnnualSelfManageModelAggregates,
  AnnualFlockModelAggregates,
  formatIntegerDollars,
} from '@flock/utils'
import theme from '@flock/shared-ui/src/theme/theme'
import { overmoonTheme } from '@flock/shared-ui'

type RentalPropertyCalculatorCashFlowGraphProps = {
  selfManageAggregates: AnnualSelfManageModelAggregates
  flockAggregates: AnnualFlockModelAggregates
}

const List = styled('ul')({
  marginBottom: '8px',
  marginTop: '0px',
})

const formatTicks = (value: any) => formatIntegerDollars(value)

const renderLegend = (props: Props) => {
  const { payload } = props

  return (
    <List>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        width="100%"
      >
        <Box
          sx={{
            background: overmoonTheme.palette.midnightBlue6.main,
            height: '10px',
            width: '10px',
            marginRight: '8px',
          }}
        />
        <Typography variant="p3">{payload![0].value}</Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="flex-end"
        width="100%"
        paddingRight="5px"
      >
        <Box
          sx={{
            background: overmoonTheme.palette.mulberry.main,
            height: '10px',
            width: '10px',
            marginRight: '8px',
          }}
        />
        <Typography variant="p3">721</Typography>
      </Box>
    </List>
  )
}

const RentalPropertyCalculatorCashFlowGraph = (
  props: RentalPropertyCalculatorCashFlowGraphProps
) => {
  const { flockAggregates, selfManageAggregates } = props
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const data = selfManageAggregates.year.map((_: number, idx: number) => {
    const cumulativeDIY = selfManageAggregates.netCashFlow
      .slice(0, idx + 1)
      .reduce((acc, curr) => acc + curr, 0)
    const cumulativeOvermoon = flockAggregates.flockCashFlow
      .slice(0, idx + 1)
      .reduce((acc, curr) => acc + curr, 0)
    return {
      name: `Year ${idx + 1}`,
      DIY: Math.round(cumulativeDIY),
      Overmoon: Math.round(cumulativeOvermoon),
    }
  })

  return (
    <Grid item xs={12}>
      <Box
        padding="24px"
        borderRadius="24px"
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        gap="16px"
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          boxShadow: '0px 8px 24px rgba(69, 73, 77, 0.08)',
        }}
      >
        <Typography variant="h3">
          Cumulative 5 Year Returns{isMobile ? '.' : ' with DIY vs Overmoon'}
        </Typography>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          sx={{
            tspan: {
              fontSize: '16px',
            },
          }}
        >
          <ResponsiveContainer width="95%" height={isMobile ? 250 : 400}>
            <BarChart
              data={data}
              margin={{
                top: 50,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid
                stroke={flockColors.lightGray}
                strokeWidth="2.5px"
                vertical={false}
              />
              <XAxis dataKey="name" />
              <YAxis tickCount={6} tickFormatter={formatTicks} />
              <Legend align="right" content={renderLegend} />
              <Bar
                dataKey="DIY"
                fill={overmoonTheme.palette.midnightBlue6.main}
              />
              <Bar
                dataKey="Overmoon"
                fill={overmoonTheme.palette.mulberry.main}
              />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Box>
    </Grid>
  )
}

export default RentalPropertyCalculatorCashFlowGraph
