import React from 'react'
import { Grid, Typography, useMediaQuery } from '@mui/material'
import theme from '@flock/shared-ui/src/theme/theme'
import {
  OM_DEFAULT_DISTRIBUTION_FACTOR,
  OM_DEFAULT_FUND_EQUITY_PERCENT_TARGET,
} from '@flock/utils'
import SectionLayout from '../components/SharedComponents/SectionLayout'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import RentalCalculatorSection from '../components/RentalPropertyCalculatorComponents/RentalPropertyCalculatorContent'
import InlineMessageAddress from '../components/SharedComponents/InlineMessageAddress'

const pageTitle = 'Overmoon | Rent vs Sell vs 721 Calculator'
const pageDescription = `See how much you can make with the Overmoon 721 Fund.`

export const Head = () => (
  <>
    <title>{pageTitle}</title>
    <meta title="title" property="og:title" content={pageTitle} />
    <meta
      name="description"
      property="og:description"
      content={pageDescription}
    />
  </>
)

const Calculator = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <PageWrapper title={pageTitle} trackingName="rental-calculator">
      <SectionLayout name="hero-section" backgroundColor="gray1.main">
        <Grid
          item
          xs={12}
          alignItems="center"
          display="flex"
          flexDirection="column"
          paddingBottom="0px"
        >
          <Typography
            variant="h1"
            color="midnightBlue.main"
            sx={{
              paddingBottom: '0rem',
              textAlign: isMobile ? 'center' : 'unset',
            }}
          >
            Rent vs Sell vs 721
          </Typography>
        </Grid>
      </SectionLayout>
      <RentalCalculatorSection />
      <SectionLayout
        name="hero-section"
        backgroundColor="gray1.main"
        verticalPadding="32px"
      >
        <Grid item xs={12}>
          <InlineMessageAddress
            text={`Ready to put the "passive" into passive income?`}
            variant="button"
            label="Get Started"
            buttonVariant="primary"
          />
        </Grid>
      </SectionLayout>
      <SectionLayout
        name="hero-section"
        backgroundColor="gray1.main"
        verticalPadding="0px"
      >
        <Grid item xs={12}>
          <Typography
            component="p"
            variant="disclaimer"
            color="disclaimer.main"
          >
            Projection Assumptions: Projections assume{' '}
            {(1 - OM_DEFAULT_FUND_EQUITY_PERCENT_TARGET) * 100}% loan-to-value
            and fixed net operating income margins. Model assumes that Overmoon
            sources yields to maintain a{' '}
            {(OM_DEFAULT_DISTRIBUTION_FACTOR * 100).toFixed(1)}% distribution.{' '}
            {isMobile && (
              <>
                <br /> <br />
              </>
            )}
            Disclaimer: All content presented herein is purely informational and
            is not an offer to buy or sell securities or a solicitation of any
            offer to buy or sell securities. This is not personalized investment
            advice, nor should it be construed as financial, legal, or tax
            advice. A professional financial advisor, attorney, and/or tax
            professional should be consulted regarding your specific financial,
            legal, and/or tax situation. In considering the target performance
            information contained herein, prospective investors should bear in
            mind that targeted performance is not a guarantee, projection or
            prediction and is not necessarily indicative of future results.
            There can be no assurance that targeted returns will be met or that
            the Fund will be able to implement its investment strategy and
            investment approach or achieve its investment objectives. Actual
            gross and net returns for the Fund may vary significantly from the
            targeted returns set forth herein.
          </Typography>
        </Grid>
      </SectionLayout>
    </PageWrapper>
  )
}

export default Calculator
