import {
  Sidebar,
  MoneyFilledIcon,
  MoneyOutlinedIcon,
  overmoonTheme,
} from '@flock/shared-ui'
import {
  Box,
  Button,
  Grid,
  useMediaQuery,
  Typography,
  Switch,
  debounce,
  FormControlLabel,
} from '@mui/material'

import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import {
  AnnualFlockModelAggregates,
  AnnualSelfManageModelAggregates,
  calculateFlockFinancialModel,
  calculateSelfManageFinancialModel,
  CompoundingGrowth,
  OM_DEFAULT_CLOSING_AND_LEGAL_FEE,
  OM_DEFAULT_DISTRIBUTION_FACTOR,
  OM_DEFAULT_FUND_EQUITY_PERCENT_TARGET,
  OM_DEFAULT_ONBOARDING_FEE,
  FlockCalculatorInputs,
  formatIntegerDollars,
  SelfManageCalculatorInputs,
  sellVersusFlockCalculator,
} from '@flock/utils'
import { scrollIntoView, useInView } from '../utils'
import SectionLayout from '../SharedComponents/SectionLayout'
import RentalPropertyCalculatorCashFlowTable from './RentalPropertyCalculatorCashFlowTable'
import RentalPropertyCalculatorCashFlowGraph from './RentalPropertyCalculatorCashFlowGraph'
import RentalPropertyCalculatorSalesGraph from './RentalPropertyCalculatorSalesGraph'
import RentalPropertyCalculatorSalesTable from './RentalPropertyCalculatorSalesTable'
import ControlledTextField from '../CashFlowComponents/ControlledTextField'

const defaultInputs = {
  propertyValue: 1000000,
  annualRent: 100000,
  remainingMortgageAmount: 350000,
  equityTakeout: 0,
  onboardingFee: OM_DEFAULT_ONBOARDING_FEE,
  mortgageInterestRate: 0.04,
  mortgageYearsRemaining: 10,
  vacancyRate: 0,
  capexCosts: 0,
  annualTurnCost: 0,
  monthlyMaintenanceCost: 500,
  insurance: 750,
  leasingCommissions: 0,
  propertyTaxes: 6000,
  propertyManagementFees: 0.25,
  capitalReserve: 500,
  hpa: 0.03,
  rentGrowth: 0.02,
  currentHouseValue: 1000000,
  purchasePrice: 500000,
  dateOfPurchase: new Date(),
  outstandingMortgageAmount: 50000,
  purchaseYear: 2005,
  monthlyHoaFees: 175,
  monthlyUtilities: 750,
}

const sectionIds = ['overview', 'rent-it-out', 'sell']

const RentalPropertyCalculatorContent = () => {
  const isMobile = useMediaQuery(overmoonTheme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(overmoonTheme.breakpoints.down('md'))

  const [activeStep, setActiveStep] = useState(0)

  // These are the values that are actually used for the calculator
  const [calculatedSelfManageAggregates, setCalculatedSelfManageAggregates] =
    useState<AnnualSelfManageModelAggregates>()
  const [calculatedFlockAggregates, setCalculatedFlockAggregates] =
    useState<AnnualFlockModelAggregates>()

  const [selfManageCashFlow, setSelfManageCashFlow] = useState<number>(0)
  const [flockCashFlow, setFlockCashFlow] = useState<number>(0)

  const [sellingNetValue, setSellingNetValue] = useState(0)
  const [contributingNetValue, setContributingNetValue] = useState(0)

  const [sellingCosts, setSellingCosts] = useState<any>()
  const [flockCosts, setFlockCosts] = useState<any>()

  const [showAdvanced, setShowAdvanced] = useState(false)

  const [calculatedAnnualRent, setCalculatedAnnualRent] = useState(30000)
  const [calculatedPropertyValue, setCalculatedPropertyValue] = useState(400000)

  const { control } = useForm({
    mode: 'onBlur',
    defaultValues: {
      ...defaultInputs,
      hasMortgage: defaultInputs.remainingMortgageAmount > 0,
    },
  })

  const fields = useWatch({ control })

  const sideBarItems = [
    {
      id: 'overview',
      title: 'Overview.',
      description: 'See how much you can make with the Overmoon 721 Fund.',
    },
    {
      id: 'rent-it-out',
      title: 'Rent It Out.',
      description:
        calculatedFlockAggregates &&
        calculatedSelfManageAggregates &&
        flockCashFlow > selfManageCashFlow
          ? `If your rental income is ${formatIntegerDollars(
              calculatedAnnualRent
            )}/yr, you'll make ${formatIntegerDollars(
              Math.round(flockCashFlow) - Math.round(selfManageCashFlow)
            )} more per year with the Overmoon 721 Fund.`
          : `See what you can make with the Overmoon 721 Fund.`,
    },
    {
      id: 'sell',
      title: 'Sell.',
      description:
        flockCosts && sellingCosts && contributingNetValue > sellingNetValue
          ? `If you sell for ${formatIntegerDollars(
              calculatedPropertyValue
            )}, you'll keep ${formatIntegerDollars(
              contributingNetValue - sellingNetValue
            )} more with the Overmoon 721 Fund.`
          : `See what you can keep with the Overmoon 721 Fund.`,
    },
  ]

  const currentSection = useInView(sectionIds)

  const onChange = (newTab: number) => {
    scrollIntoView(sectionIds[newTab])
  }

  useEffect(() => {
    setActiveStep(sectionIds.indexOf(currentSection))
  }, [currentSection])

  const updateFields = (newFields: any) => {
    const {
      propertyValue,
      remainingMortgageAmount,
      mortgageYearsRemaining,
      mortgageInterestRate,
      hpa,
      rentGrowth,
      vacancyRate,
      annualTurnCost,
      monthlyMaintenanceCost,
      propertyManagementFees,
      propertyTaxes,
      insurance,
      capitalReserve,
      leasingCommissions,
      monthlyHoaFees,
      monthlyUtilities,
    } = newFields

    const annualRent = newFields.annualRent || 0

    const targetContributionDate = new Date('2023-02-01')

    const selfManageInputs: SelfManageCalculatorInputs = {
      propertyValue,
      monthlyRent: annualRent / 12,
      remainingMortgageAmount,
      mortgageYearsRemaining,
      mortgageInterestRate,
      hpa,
      rentGrowth,
      vacancyRate,
      annualTurnCost,
      monthlyMaintenanceCost,
      propertyManagementFees,
      propertyTaxes,
      insurance,
      capitalReserve,
      leasingCommissions,
      monthlyHoaFees,
      monthlyUtilities,
      targetContributionDate,
    }

    const { onboardingFee, capexCosts } = newFields

    const flockInputs: FlockCalculatorInputs = {
      propertyValue,
      remainingMortgageAmount,
      hpa: 0,
      flockDistributionRate: OM_DEFAULT_DISTRIBUTION_FACTOR,
      closingAndLegalFee: OM_DEFAULT_CLOSING_AND_LEGAL_FEE,
      reinvestDistributions: false,
      equityTakeout: 0,
      fundEquityPercentTarget: OM_DEFAULT_FUND_EQUITY_PERCENT_TARGET,
      hpaCompounding: CompoundingGrowth.ANNUALLY,
      onboardingFee,
      capexCosts,
      targetContributionDate,
      overmoonDistributionScaling: true,
    }

    if (!newFields.hasMortgage) {
      selfManageInputs.remainingMortgageAmount = 0
      selfManageInputs.mortgageYearsRemaining = 0
      selfManageInputs.mortgageInterestRate = 0
      flockInputs.remainingMortgageAmount = 0
    }
    // Don't update the fields if the field value is invalid or undefined
    const isValidInput =
      !Object.keys(selfManageInputs).some((key: string) =>
        Number.isNaN(selfManageInputs[key as keyof SelfManageCalculatorInputs])
      ) &&
      !(
        newFields.hasMortgage &&
        remainingMortgageAmount &&
        !mortgageYearsRemaining
      )

    if (!isValidInput) {
      return
    }

    const selfManageAggregates =
      calculateSelfManageFinancialModel(selfManageInputs)
    const flockAggregates = calculateFlockFinancialModel(flockInputs)

    const { purchasePrice, dateOfPurchase } = newFields

    const calculatorInputs: any = {
      currentHouseValue: propertyValue,
      purchasePrice,
      dateOfPurchase,
      monthlyRent: calculatedAnnualRent / 12,
      outstandingMortgageAmount: 0,
      closingAndLegalFeeOverride: OM_DEFAULT_CLOSING_AND_LEGAL_FEE,
      onboardingFeeOverride: OM_DEFAULT_ONBOARDING_FEE,
      tenantTurnCost: 0,
    }

    if (newFields.hasMortgage) {
      calculatorInputs.outstandingMortgageAmount = remainingMortgageAmount
    }

    const calculatorOutput = sellVersusFlockCalculator(calculatorInputs)
    const { selling, contributingToFlock } = calculatorOutput
    setSellingNetValue(selling.netValue)
    setContributingNetValue(contributingToFlock.netValue)

    setSellingCosts(selling)
    setFlockCosts(contributingToFlock)

    setCalculatedSelfManageAggregates(selfManageAggregates)
    setCalculatedFlockAggregates(flockAggregates)
    setSelfManageCashFlow(selfManageAggregates.netCashFlow[0])
    setFlockCashFlow(flockAggregates.flockCashFlow[0])

    setCalculatedAnnualRent(annualRent || 0)
    setCalculatedPropertyValue(propertyValue)
  }

  const debounceUpdateFields = useMemo(() => debounce(updateFields, 1000), [])

  useEffect(() => {
    debounceUpdateFields(fields)
  }, [fields, debounceUpdateFields])

  return (
    <SectionLayout
      name="rental-property-calculator"
      backgroundColor="gray1.main"
      verticalPadding="0px"
    >
      {!isTablet && (
        <Grid
          sx={{
            position: 'sticky',
            top: '8%',
            height: 'fit-content',
            zIndex: 999,
          }}
          item
          xs={3}
          display="flex"
          justifyContent="flex-end"
        >
          <Box width="100%" pt="0px" pb="80px">
            <Sidebar
              items={sideBarItems}
              onChange={onChange}
              selected={activeStep}
            />
          </Box>
        </Grid>
      )}
      {isMobile && (
        <Grid
          sx={{
            position: 'sticky',
            top: '38px',
            height: 'fit-content',
            zIndex: 999,
            paddingTop: '0px',
          }}
          item
          display="flex"
          justifyContent="flex-end"
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            sx={{
              backgroundColor: 'gray1.main',
              paddingTop: '16px',
              paddingBottom: '16px',
            }}
          >
            <Button
              style={{
                borderRadius: 24,
                backgroundColor: 'white',
                fontSize: '18px',
                color: 'black',
                marginRight: '4px',
                paddingLeft: '16px',
                paddingRight: '12px',
                textAlign: 'left',
              }}
              variant="contained"
              onClick={() => onChange(1)}
            >
              <Box display="flex" flexDirection="column">
                <Typography variant="h4">Rent It Out.</Typography>
                {calculatedFlockAggregates &&
                  calculatedSelfManageAggregates &&
                  flockCashFlow > selfManageCashFlow && (
                    <Typography variant="h4">
                      {formatIntegerDollars(Math.round(flockCashFlow))} cash
                      flow.
                    </Typography>
                  )}
              </Box>
            </Button>
            <Button
              style={{
                borderRadius: 24,
                backgroundColor: 'white',
                fontSize: '18px',
                color: 'black',
                marginLeft: '4px',
                paddingLeft: '16px',
                paddingRight: '12px',
                textAlign: 'left',
              }}
              variant="contained"
              onClick={() => onChange(2)}
            >
              <Box display="flex" flexDirection="column">
                <Typography variant="h4">Sell.</Typography>
                {flockCosts &&
                  sellingCosts &&
                  contributingNetValue > sellingNetValue && (
                    <Typography variant="h4">
                      {formatIntegerDollars(Math.round(contributingNetValue))}{' '}
                      net income.
                    </Typography>
                  )}
              </Box>
            </Button>
          </Box>
        </Grid>
      )}
      <Grid
        container
        item
        xs={isTablet ? 12 : 9}
        direction="column"
        sx={{
          backgroundColor: undefined,
        }}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          gap="32px"
          pb="32px"
          id="overview"
        >
          <Box position="absolute" top="0" />
          <Box
            pb="0px"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <MoneyFilledIcon height="28px" width="28px" />
            {!isMobile && (
              <Grid item>
                <Typography sx={{ marginLeft: '1rem' }}>
                  My house is worth
                </Typography>
              </Grid>
            )}
            <Grid item sx={{ width: '150px', marginLeft: '1rem' }}>
              <ControlledTextField
                name="propertyValue"
                label=""
                format="dollars"
                control={control}
                paddingTop="0px"
              />
            </Grid>
            {isMobile && (
              <Typography sx={{ marginLeft: '1rem' }}>house value</Typography>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <MoneyOutlinedIcon height="28px" width="28px" />
            {!isMobile && (
              <Typography sx={{ marginLeft: '1rem' }}>
                {' '}
                I expect to gross
              </Typography>
            )}
            <Grid item sx={{ width: '150px', marginLeft: '1rem' }}>
              <ControlledTextField
                name="annualRent"
                label=""
                format="dollars"
                control={control}
                paddingTop="0px"
              />
            </Grid>
            {!isMobile && (
              <Typography sx={{ marginLeft: '1rem' }}>
                in annual rental revenue
              </Typography>
            )}
            {isMobile && (
              <Typography sx={{ marginLeft: '1rem' }}>/mo rent</Typography>
            )}
          </Box>
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                paddingBottom: '16px',
              }}
            >
              <FormControlLabel
                label=""
                sx={{ marginRight: '0px' }}
                control={
                  <Controller
                    name="hasMortgage"
                    control={control}
                    render={({ field: fieldProps }) => (
                      <Grid item xs={12}>
                        <Box sx={{ paddingLeft: '10px', paddingRight: '10px' }}>
                          <Switch
                            {...fieldProps}
                            checked={fieldProps.value}
                            onChange={(e) =>
                              fieldProps.onChange(e.target.checked)
                            }
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </Box>
                      </Grid>
                    )}
                  />
                }
              />
              <Typography>I have a mortgage</Typography>
            </Box>
            {fields.hasMortgage && (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: isMobile ? 'left' : 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingTop: isMobile ? '16px' : '0px',
                    }}
                  >
                    <Grid item sx={{ width: '150px' }}>
                      <ControlledTextField
                        name="remainingMortgageAmount"
                        label=""
                        format="dollars"
                        control={control}
                        disabled={!fields.hasMortgage}
                        paddingTop="0px"
                      />
                    </Grid>
                    <Typography
                      sx={{ marginLeft: '1rem', marginRight: '1rem' }}
                    >
                      mortgage
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingTop: isMobile ? '16px' : '0px',
                    }}
                  >
                    <Grid item sx={{ width: '70px' }}>
                      <ControlledTextField
                        name="mortgageYearsRemaining"
                        label=""
                        format="number"
                        control={control}
                        disabled={!fields.hasMortgage}
                        paddingTop="0px"
                      />
                    </Grid>
                    <Typography
                      sx={{ marginLeft: '1rem', marginRight: '1rem' }}
                    >
                      years left
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingTop: isMobile ? '16px' : '0px',
                    }}
                  >
                    <Grid item sx={{ width: '75px' }}>
                      <ControlledTextField
                        name="mortgageInterestRate"
                        label=""
                        format="decimalPercent"
                        control={control}
                        disabled={!fields.hasMortgage}
                        paddingTop="0px"
                      />
                    </Grid>
                    <Typography sx={{ marginLeft: '1rem' }}>
                      interest
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems: isMobile ? 'left' : 'center',
                    paddingTop: '16px',
                    paddingBottom: '32px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Grid item sx={{ width: '150px' }}>
                      <ControlledTextField
                        name="purchasePrice"
                        label=""
                        format="dollars"
                        control={control}
                        disabled={!fields.hasMortgage}
                        paddingTop="0px"
                      />
                    </Grid>
                    <Typography
                      sx={{ marginLeft: '1rem', marginRight: '1rem' }}
                    >
                      purchase price {isMobile ? '' : 'in'}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      paddingTop: isMobile ? '16px' : '0px',
                    }}
                  >
                    <Grid item sx={{ width: '100px' }}>
                      <ControlledTextField
                        name="purchaseYear"
                        label=""
                        format="year"
                        control={control}
                        disabled={!fields.hasMortgage}
                        paddingTop="0px"
                      />
                    </Grid>
                    {isMobile && (
                      <Typography sx={{ marginLeft: '1rem' }}>
                        purchase year
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingBottom: '16px',
                }}
              >
                <Switch
                  checked={showAdvanced}
                  onChange={() => setShowAdvanced(!showAdvanced)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Typography sx={{ paddingLeft: '10px' }}>
                  Advanced options
                </Typography>
              </Box>
            </Grid>
            {showAdvanced && (
              <Grid
                container
                rowSpacing={0}
                columnSpacing={5}
                sx={{ paddingLeft: '0px', paddingTop: '0px' }}
              >
                <Grid item xs={12} sm={4} sx={{ marginTop: '16px' }}>
                  <ControlledTextField
                    name="monthlyMaintenanceCost"
                    label="Monthly Maintenance"
                    tooltip="Monthly maintenance and repairs"
                    format="dollars"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ marginTop: '16px' }}>
                  <ControlledTextField
                    name="propertyManagementFees"
                    label="Prop. Management Fee"
                    tooltip="Percentage of effective gross rent, annual"
                    format="decimalPercent"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ marginTop: '16px' }}>
                  <ControlledTextField
                    name="propertyTaxes"
                    label="Property Taxes"
                    tooltip="Property taxes, annual"
                    format="dollars"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ marginTop: '16px' }}>
                  <ControlledTextField
                    name="insurance"
                    label="Insurance"
                    tooltip="Cost of insurance, annual"
                    format="dollars"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ marginTop: '16px' }}>
                  <ControlledTextField
                    name="capitalReserve"
                    label="Capital Reserve"
                    tooltip="Monthly cash flow reserved for future replacement of appliances, systems, etc."
                    format="dollars"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ marginTop: '16px' }}>
                  <ControlledTextField
                    name="monthlyHoaFees"
                    label="Monthly HOA Fee"
                    tooltip=""
                    format="dollars"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ marginTop: '16px' }}>
                  <ControlledTextField
                    name="monthlyUtilities"
                    label="Monthly Utilities"
                    tooltip=""
                    format="dollars"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ marginTop: '16px' }}>
                  <ControlledTextField
                    name="hpa"
                    label="Home Price Appreciation"
                    tooltip="Annual home price appreciation compounded monthly"
                    format="decimalPercent"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={4} sx={{ marginTop: '16px' }}>
                  <ControlledTextField
                    name="rentGrowth"
                    label="Rent Growth"
                    format="decimalPercent"
                    tooltip="Annual rent growth compounded annually"
                    control={control}
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Grid>

      {!isTablet && (
        <Grid
          item
          xs={3}
          sx={{
            position: 'relative',
            backgroundColor: 'gray1.main',
            '&:before': {
              content: '""',
              position: 'absolute',
              /* fill vertically */
              top: 0,
              bottom: 0,
              width: '9600px',
              right: '100%',
              background: overmoonTheme.palette.gray1.main,
            },
          }}
        />
      )}
      <Grid
        item
        xs={isTablet ? 12 : 9}
        sx={{
          backgroundColor: 'gray1.main',
        }}
      >
        {isMobile && (
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            gap="32px"
            sx={undefined}
          >
            <Box position="absolute" mt="-188px" />
          </Box>
        )}
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          gap="32px"
          pt="0px"
          pb="0px"
          id={isMobile ? undefined : 'rent-it-out'}
          sx={undefined}
        >
          {!isMobile && <Box mt="-128px" position="absolute" />}

          <Grid item xs={12}>
            <Box
              padding="24px"
              borderRadius="24px"
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              gap="16px"
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                boxShadow: '0px 8px 24px rgba(69, 73, 77, 0.08)',
              }}
            >
              {isMobile && (
                <>
                  <Typography variant="h4">Rent It Out.</Typography>
                  {calculatedFlockAggregates &&
                    calculatedSelfManageAggregates &&
                    flockCashFlow > selfManageCashFlow && (
                      <>
                        <Typography color="#136D42" variant="h3">
                          {formatIntegerDollars(Math.round(flockCashFlow))}
                        </Typography>
                        <Typography variant="body1">
                          If your rental income is{' '}
                          {formatIntegerDollars(calculatedAnnualRent)}/yr,
                          you&apos;ll make{' '}
                          {formatIntegerDollars(
                            Math.round(flockCashFlow - selfManageCashFlow)
                          )}{' '}
                          more per year with Overmoon.
                        </Typography>
                      </>
                    )}
                </>
              )}

              <Typography>
                Many vacation home owners underestimate the cost of owning and
                operating an investment property. Between your mortgage and
                insurance, maintenance, property taxes and vacancies, a positive
                cash flow that’s worth the effort can be hard to come by. Even
                time spent worrying and managing comes at a cost.
              </Typography>
            </Box>
          </Grid>
          {calculatedSelfManageAggregates && calculatedFlockAggregates && (
            <RentalPropertyCalculatorCashFlowGraph
              selfManageAggregates={calculatedSelfManageAggregates}
              flockAggregates={calculatedFlockAggregates}
            />
          )}
          {calculatedSelfManageAggregates && calculatedFlockAggregates && (
            <RentalPropertyCalculatorCashFlowTable
              selfManageAggregates={calculatedSelfManageAggregates}
              flockAggregates={calculatedFlockAggregates}
            />
          )}
        </Box>
      </Grid>

      {!isTablet && <Grid item xs={3} sx={undefined} />}
      <Grid
        item
        xs={isTablet ? 12 : 9}
        sx={{
          backgroundColor: undefined,
        }}
      >
        {isMobile && (
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            gap="32px"
            id="sell"
            sx={undefined}
          >
            <Box position="absolute" mt="-100px" />
          </Box>
        )}
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          gap="32px"
          pt="84px"
          pb="80px"
          id={isMobile ? undefined : 'sell'}
          sx={undefined}
        >
          {!isMobile && <Box mt="-228px" position="absolute" />}
          <Grid item xs={12}>
            <Box
              padding="24px"
              borderRadius="24px"
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              gap="16px"
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                boxShadow: '0px 8px 24px rgba(69, 73, 77, 0.08)',
              }}
            >
              {isMobile && (
                <>
                  <Typography variant="h2">Sell.</Typography>
                  {flockCosts &&
                    sellingCosts &&
                    contributingNetValue > sellingNetValue && (
                      <>
                        <Typography color="#136D42" variant="h3">
                          {formatIntegerDollars(
                            Math.round(contributingNetValue)
                          )}
                        </Typography>
                        <Typography variant="body1">
                          Keep{' '}
                          {formatIntegerDollars(
                            Math.round(contributingNetValue) -
                              Math.round(sellingNetValue)
                          )}{' '}
                          more when you sell for{' '}
                          {formatIntegerDollars(calculatedPropertyValue)} by
                          using Overmoon.
                        </Typography>
                      </>
                    )}
                </>
              )}
              <Typography>
                Selling your investment property the traditional way can be
                expensive. There are costs to ready the property, stage and sell
                often with a realtor, not to mention the tax bills that come
                after.
              </Typography>
            </Box>
          </Grid>
          {sellingCosts && flockCosts && (
            <RentalPropertyCalculatorSalesGraph
              selling={sellingCosts}
              contributingToFlock={flockCosts}
            />
          )}
          {sellingCosts && flockCosts && (
            <RentalPropertyCalculatorSalesTable
              selling={sellingCosts}
              contributingToFlock={flockCosts}
            />
          )}
        </Box>
      </Grid>
    </SectionLayout>
  )
}

export default RentalPropertyCalculatorContent
