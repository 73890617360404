import {
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  useMediaQuery,
} from '@mui/material'
import { Info } from '@mui/icons-material'

import React from 'react'
import {
  formatIntegerDollars,
  AnnualSelfManageModelAggregates,
  AnnualFlockModelAggregates,
} from '@flock/utils'
import theme from '@flock/shared-ui/src/theme/theme'

type CashFlowCalculatorTableProps = {
  selfManageAggregates: AnnualSelfManageModelAggregates
  flockAggregates: AnnualFlockModelAggregates
  cashFlowYear?: number
}

const StyledTableCell = styled(TableCell)({
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  textAlign: 'right',
})

const StyledInfo = styled(Info)({
  fontSize: '1rem',
  cursor: 'pointer',
  marginLeft: '4px',
  marginTop: '-4px',
})

const TableWrapper = styled('div')({
  width: '100%',
  overflowX: 'auto',
})

type RowProps = {
  name: string
  data: number[]
  color?: string
  backgroundColor?: string
  bold?: boolean
  cashFlowYear?: boolean
}

const Row = (props: RowProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { name, data, color, backgroundColor, bold, cashFlowYear } = props
  return (
    <TableRow
      sx={{
        backgroundColor: backgroundColor || 'white',
        borderTop: bold ? '2px solid black' : 'unset',
      }}
    >
      <StyledTableCell
        sx={{
          fontWeight: bold ? 'bold' : 'normal',
          textAlign: 'left',
          minWidth: isMobile && cashFlowYear ? '0rem' : '12.5rem',
          paddingLeft: isMobile && cashFlowYear ? '0px' : undefined,
          paddingRight: isMobile && cashFlowYear ? '0px' : undefined,
          fontSize: isMobile && cashFlowYear ? '12px' : undefined,
          width: isMobile && cashFlowYear ? '35%' : undefined,
          color,
        }}
      >
        {name}
      </StyledTableCell>
      {data.map((dataPoint) => (
        <StyledTableCell
          sx={{
            fontWeight: bold ? 'bold' : 'normal',
            color,
            fontSize: isMobile && cashFlowYear ? '12px' : undefined,
            paddingLeft: isMobile && cashFlowYear ? '0px' : undefined,
            paddingRight: isMobile && cashFlowYear ? '0px' : undefined,
          }}
        >
          {formatIntegerDollars(dataPoint)}
        </StyledTableCell>
      ))}
    </TableRow>
  )
}

Row.defaultProps = {
  backgroundColor: 'white',
  color: 'black',
  bold: false,
  cashFlowYear: false,
}

const CashFlowCalculatorTable = (props: CashFlowCalculatorTableProps) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { selfManageAggregates, flockAggregates, cashFlowYear } = props
  if (!selfManageAggregates || !flockAggregates) {
    return null
  }
  const {
    year,
    baseRentalRevenue,
    vacancy,
    turnCost,
    effectiveGrossRent,
    repairsAndMaintenance,
    propertyManagementFee,
    propertyTaxes,
    insurance,
    netOperatingIncome,
    capitalReserve,
    hoaFees,
    utilities,
    leasingCommission,
    cashFlowAfterCapital,
    mortgagePayment,
    mortgageInterest,
    mortgagePrincipal,
    netCashFlow,
  } = selfManageAggregates

  const { flockCashFlow } = flockAggregates
  const hasMortgage = !mortgagePayment.every((payment: number) => payment === 0)
  const decimal = year[0] % 1
  let tooltipText = ''
  if (decimal !== 0) {
    const month = Math.round(decimal * 12)
    const date = new Date()
    date.setMonth(month)
    tooltipText = `Calculations based on a start date of ${date.toLocaleDateString(
      'en-US',
      {
        month: 'long',
      }
    )} 1st, ${Math.floor(year[0])}.`
  }

  if (cashFlowYear) {
    const yearIndex = year.findIndex((yr) => Math.floor(yr) === cashFlowYear)
    let tooltip
    if (yearIndex === 0) {
      // first year set tooltip to indicate starting month for calculations
      tooltip = tooltipText
    }
    return (
      <TableWrapper>
        <Table sx={{ marginTop: cashFlowYear ? '0rem' : '2rem' }}>
          <TableHead>
            <TableRow>
              <StyledTableCell
                sx={{
                  color: 'gray8.main',
                  fontWeight: cashFlowYear ? '500' : 'bold',
                  fontSize: isMobile && cashFlowYear ? '12px' : '1.05rem',
                  textAlign: 'start',
                  paddingLeft: isMobile && cashFlowYear ? '0px' : undefined,
                  paddingRight: isMobile && cashFlowYear ? '0px' : undefined,
                  width: isMobile && cashFlowYear ? '25%' : undefined,
                  lineHeight: cashFlowYear ? '18px' : undefined,
                }}
              >
                Cash Flow
                {tooltip && (
                  <Tooltip
                    title={tooltip}
                    arrow
                    placement="top"
                    enterTouchDelay={0}
                  >
                    <StyledInfo sx={{ color: 'gray8.main' }} />
                  </Tooltip>
                )}
              </StyledTableCell>
              <StyledTableCell
                sx={{
                  color: 'gray8.main',
                  fontWeight: cashFlowYear ? '500' : 'bold',
                  fontSize: isMobile && cashFlowYear ? '12px' : '1.05rem',
                  paddingLeft: isMobile && cashFlowYear ? '0px' : undefined,
                  paddingRight: isMobile && cashFlowYear ? '0px' : undefined,
                  width: isMobile && cashFlowYear ? '25%' : undefined,
                  lineHeight: cashFlowYear ? '18px' : undefined,
                }}
              >
                DIY
              </StyledTableCell>
              <StyledTableCell
                sx={{
                  color: 'gray8.main',
                  fontWeight: cashFlowYear ? '500' : 'bold',
                  fontSize: isMobile && cashFlowYear ? '12px' : '1.05rem',
                  paddingLeft: isMobile && cashFlowYear ? '0px' : undefined,
                  paddingRight: isMobile && cashFlowYear ? '0px' : undefined,
                  width: isMobile && cashFlowYear ? '25%' : undefined,
                }}
              >
                Overmoon Fund
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Row
              name="Effective Gross Income"
              data={[effectiveGrossRent[yearIndex], flockCashFlow[yearIndex]]}
              cashFlowYear
              bold
            />
            <Row
              name="Repairs, Maintenance"
              data={[repairsAndMaintenance[yearIndex], 0]}
              cashFlowYear
            />
            <Row
              name="Property Management Fee"
              data={[propertyManagementFee[yearIndex], 0]}
              cashFlowYear
            />
            <Row
              name="Property Taxes"
              data={[propertyTaxes[yearIndex], 0]}
              cashFlowYear
            />
            <Row
              name="Insurance"
              data={[insurance[yearIndex], 0]}
              cashFlowYear
            />
            <Row name="HOA Fees" data={[hoaFees[yearIndex], 0]} cashFlowYear />
            <Row
              name="Utilities"
              data={[utilities[yearIndex], 0]}
              cashFlowYear
            />
            <Row
              name="Net Operating Income"
              data={[netOperatingIncome[yearIndex], flockCashFlow[yearIndex]]}
              bold
              cashFlowYear
            />
            <Row
              name="Capital Reserve"
              data={[capitalReserve[yearIndex], 0]}
              cashFlowYear
            />
            <Row
              name="Leasing Commission"
              data={[leasingCommission[yearIndex], 0]}
              cashFlowYear
            />

            {hasMortgage && (
              <>
                <Row
                  name="Cash Flow After Capital Items"
                  data={[
                    cashFlowAfterCapital[yearIndex],
                    flockCashFlow[yearIndex],
                  ]}
                  bold
                  cashFlowYear
                />
                <Row
                  name="Interest Payment"
                  data={[mortgageInterest[yearIndex], 0]}
                  cashFlowYear
                />
                <Row
                  name="Principal Payment"
                  data={[mortgagePrincipal[yearIndex], 0]}
                  cashFlowYear
                />
              </>
            )}
            <Row
              name="Total Cash Flow"
              data={[netCashFlow[yearIndex], flockCashFlow[yearIndex]]}
              backgroundColor="lavender3.main"
              bold
              cashFlowYear
            />
          </TableBody>
        </Table>
      </TableWrapper>
    )
  }

  return (
    <TableWrapper>
      <Table sx={{ marginTop: '2rem' }}>
        <TableHead>
          <TableRow>
            <StyledTableCell />
            {year.map((yr: number, index: number) => (
              <StyledTableCell
                sx={{
                  color: 'gray8.main',
                  fontWeight: 'bold',
                  fontSize: '1.05rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {Math.floor(yr)}
                {index === 0 && (
                  <Tooltip
                    title={tooltipText}
                    arrow
                    placement="top"
                    enterTouchDelay={0}
                  >
                    <StyledInfo sx={{ color: 'gray8.main' }} />
                  </Tooltip>
                )}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <Row name="Base Rental Revenue" data={baseRentalRevenue} bold />
          <Row name="Vacancy" data={vacancy} />
          <Row name="Turn Cost" data={turnCost} />
          <Row name="Effective Gross Rent" data={effectiveGrossRent} bold />
          <Row name="Repairs, Maintenance" data={repairsAndMaintenance} />
          <Row name="Property Management Fee" data={propertyManagementFee} />
          <Row name="Property Taxes" data={propertyTaxes} />
          <Row name="Insurance" data={insurance} />
          <Row name="Net Operating Income" data={netOperatingIncome} bold />
          <Row name="Capital Reserve" data={capitalReserve} />
          <Row name="Leasing Commission" data={leasingCommission} />
          {hasMortgage && (
            <>
              <Row
                name="Cash Flow After Capital Items"
                data={cashFlowAfterCapital}
                bold
              />
              <Row name="Interest Payment" data={mortgageInterest} />
              <Row name="Principal Payment" data={mortgagePrincipal} />
            </>
          )}
          <Row
            name="Do It Yourself Cash Flow"
            data={netCashFlow}
            backgroundColor="lavender3.main"
            bold
          />
        </TableBody>
      </Table>
      <br />
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell />
            {year.map((yr: number, index: number) => (
              <StyledTableCell sx={{ color: 'gray8.main', fontWeight: 'bold' }}>
                {Math.floor(yr)}
                {index === 0 && (
                  <Tooltip
                    title={tooltipText}
                    arrow
                    placement="top"
                    enterTouchDelay={0}
                  >
                    <StyledInfo sx={{ color: 'gray8.main' }} />
                  </Tooltip>
                )}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <Row
            name="Overmoon Cash Flow"
            data={flockCashFlow}
            bold
            color="white!important"
            backgroundColor="moneyGreen.main"
          />
        </TableBody>
      </Table>
    </TableWrapper>
  )
}

export default CashFlowCalculatorTable

CashFlowCalculatorTable.defaultProps = {
  cashFlowYear: undefined,
}
